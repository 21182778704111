// main: style.scss

@media all and (max-width:767px) {
  #menu {
    border-left: 10px solid $gray-dark;
    padding-left: 30px;
    background: $gray-lighter;
  }
  .slideout-menu {
    position: fixed;
    left: auto;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 256px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    display: none;
  }

  .slideout-panel {
    position: relative;
    z-index: 1;
  }

  .slideout-open,
  .slideout-open body,
  .slideout-open .slideout-panel {
    overflow: hidden;
  }

  .slideout-open .slideout-menu {
    display: block;
  }
}
