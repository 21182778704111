// main: style.scss
@font-face {
    font-family: 'Verb';
    src: url('fonts/VerbRegular-webfont.eot');
    src: url('fonts/VerbRegular-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/VerbRegular-webfont.woff') format('woff'),
         url('fonts/VerbRegular-webfont.ttf') format('truetype'),
         url('fonts/VerbRegular-webfont.svg#verb_regularregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Verb Black';
    src: url('fonts/VerbBlack-webfont.eot');
    src: url('fonts/VerbBlack-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/VerbBlack-webfont.woff') format('woff'),
         url('fonts/VerbBlack-webfont.ttf') format('truetype'),
         url('fonts/VerbBlack-webfont.svg#verb_blackregular') format('svg');
    font-weight: normal;
    font-style: normal;

}


/** Custom Icon Font **/

@font-face {
  font-family: 'fontello';
  src: url('fonts/fontello/fontello.eot?17177483');
  src: url('fonts/fontello/fontello.eot?17177483#iefix') format('embedded-opentype'),
       url('fonts/fontello/fontello.woff?17177483') format('woff'),
       url('fonts/fontello/fontello.ttf?17177483') format('truetype'),
       url('fonts/fontello/fontello.svg?17177483#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?17177483#fontello') format('svg');
  }
}
*/
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-github:before { content: '\e800'; } /* '' */
.icon-linkedin:before { content: '\e801'; } /* '' */
.icon-xing:before { content: '\e802'; } /* '' */
.icon-menu:before { content: '\e803'; } /* '' */
.icon-spin:before { content: '\e804'; } /* '' */
.icon-left:before { content: '\e805'; } /* '' */
.icon-right:before { content: '\e806'; } /* '' */
.icon-play-circled:before { content: '\e808'; } /* '' */

/*
   Animation example, for spinners
*/
.animate-spin {
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
