//main: style.scss

:focus {
  outline: none !important;
}
::-moz-focus-inner {
    border: 0;
}


$header-height: 68px !default;

html, body, #container {
  width: 100%;
  min-height: 100%;
  background: #f4f4f4;
}
.full-size {
  height: 100%;
  width: 100%;
}
#header {
  height: $header-height;
  overflow: hidden;

  >img.header-img {
    z-index: 0;
    height: $header-height;
    width: auto;
  }

  >.menu-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
    height: $header-height - 30px;
    z-index: 1;
  }
}
#content {
  height: calc(100% - 68px);
}

#content-area {
  padding: 50px 0;
}

.jumbotron {
  font-family: "Verb Black", sans-serif;
  text-align: center;
  text-transform: uppercase;
  border-top: 10px solid #333;
  border-bottom: 10px solid #333;
}

#inner-content {
  margin: 0 50px;
  border-bottom: 10px solid #333;
  background: #fff;
  padding: 40px;
  min-height: 260px;

  .pf-text {
    padding: 25px 0;
  }
}



.nav {
  >li {
    margin: 20px 0;
    >a {
      font-family: "Verb Black";
      display: inline-block;
      background-color: $gray-dark;
      border-left: 0px solid $gray-dark;

      &.active {
        padding-left: 10px;
        background-color: lighten($gray-dark, 5%);
        color: darken($brand-primary, 10%);
        border-left: 5px solid darken($brand-primary, 10%);
      }

      &:hover, &:focus {
        color: darken($brand-primary, 15%);
      }

    }
  }
}

.social-media {
  margin-left: -5px;
  margin-top: 10px;
  a {
    border-radius: 100%;
    background-color: $gray-dark;
    padding: 5px;
    font-size: 1.3em;
    margin: 0 5px;
    color: $gray-lighter;
    border: 1px solid $gray-light;

    &:hover, &:active, &:focus {
      color: $brand-primary;
      background-color: $gray-light;
      border-color: $brand-primary;
    }
  }
}

#loading{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  font-size: 5.6em;
  color: darken($brand-primary, 15%);
  text-align: center;
  padding-top: 68px;
  overflow: hidden;
  text-shadow: 0px 2px 3px $gray-dark;
}
/*#content {
  position: relative;
  height: 100%;

  >* {
    height: 100%;
    min-height: 100%;
  }

  .row {
    position: absolute;
    top: 0;
    left: 0;
  }
}*/


/* entire container, keeps perspective */
.flip-container {
    perspective: 1000;
    transform-style: preserve-3d;

    .caption {
      opacity: 0;
      padding: 10px;
      color: #eee;
      width: 100%;
      z-index: 1000;
      overflow: hidden;
      text-overflow: ellipsis;
      position: absolute;
      bottom: -100%;
      left: 0;
      transition: all 0.6s;
      background: rgba(80, 80, 80, 0.7);

      .caption-title {
        font-size: 300%;
        font-family: 'Verb Black', sans-serif;
      }
    }

    /*  UPDATED! flip the pane when hovered */
    &:hover {
      .caption {
        opacity: 1;
        bottom: 0;
      }
  }
  >.flipper,
  >.flipper>.front,
  >.flipper>.back {
    display: block;
    width: 100%;
    height: 100%;
  }
}
/* flip speed goes here */
.flipper {
    transition: 1.6s;
    transform-style: preserve-3d;
    overflow: hidden;
    position: relative;

    /* hide back of pane during swap */
    .front, .back {
      display: block;
        backface-visibility: hidden;
        transition: 1.6s;
        transform-style: preserve-3d;
    }

    /*  UPDATED! front pane, placed above back */
    .front {
        z-index: 2;
        transform: rotateY(0deg);
    }

    /* back, initially hidden pane */
    .back {
        transform: rotateY(-180deg);
        position: absolute;
        top: 0;
        left: 0;
    }

    &.flip .back {
        transform: rotateY(0deg);
        box-shadow: 2px 2px 5px rgba(30,30,30,0.4);
      }

    &.flip .front {
          transform: rotateY(180deg);
          box-shadow: 2px 2px 5px rgba(30,30,30,0.4);
      }

}


/*
    Some vertical flip updates
*/
.vertical.flip-container {
    position: relative;

    &:hover {
      .back {
          transform: rotateX(0deg);
      }

      .front {
          transform: rotateX(180deg);
      }
    }
}

    .vertical .back {
        transform: rotateX(180deg);
    }

dl.dl-horizontal {
  dt {
    text-align: left;
    width: 130px;
  }
  dd {
    margin-left: 140px;
  }
}

.slick-prev, .slick-next {
  width: 25px;
  height: 44px;

  &:before {
    font-size: 50px;
  }
}

.video-overlay {
  top: 0px;
  left: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba($gray-dark,0.4);

  .icon-play-circled {
    font-size: 6em;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -67px;
    margin-left: -67px;
    color: $brand-primary;
    transition: all 0.2s;
  }
  &:hover {
    background: rgba($gray-light,0.4);
    cursor: pointer;
    .icon-play-circled {
      color: $gray-lighter;
    }
  }
}

label {
  font-weight: normal;
  font-family: 'Verb Black', sans-serif;
}

.form-control:focus {
  background-color: $brand-primary;
  box-shadow: none;
}

.btn {
  font-family: 'Verb Black', sans-serif;
}

.pager {
  li {
    >a, >span {
      border: 0;
      font-size: 2.2em;
      padding: 0;
    }

  }

  &.slider-nav {
    li {
      >span {
        color: $link-color;

        &:hover {
          cursor: pointer;
          color: $link-hover-color;
        }
      }
    }
  }
}


footer {
  font-size: 0.8em;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 50px;
}


@media all and (max-width:$screen-xs-max) {
  #inner-content {
    margin-left: 0;
    margin-right: 0;
  }

  dl.dl-horizontal {
    margin-bottom: 0;
    dd {
      margin-left: 0;
      margin-bottom: 5px;
    }
  }
}
